import React from "react";
import { requestDeleteUser } from "../firebase/userFunctions";

const UserCard = ({ user, onClick }) => {
  const username = user?.username || "Unknown User";
  const profilePicture =
    user?.profile_picture_url || "https://via.placeholder.com/150";
  const uid = user?.uid || "Unknown UID";
  const phoneNumber = user?.phoneNumber || "Unknown Phone Number";
  const dateCreated = user?.dateCreated
    ? new Date(user.dateCreated).toLocaleDateString()
    : "Unknown Date";

  const handleDelete = async (e) => {
    e.stopPropagation(); // Prevent the card's onClick event from firing
    if (window.confirm(`Are you sure you want to delete ${username}?`)) {
      try {
        await requestDeleteUser(uid);
        alert(`Deletion request for ${username} has been submitted.`);
      } catch (error) {
        console.error("Error deleting user:", error);
        alert("Failed to request user deletion. Please try again later.");
      }
    }
  };

  return (
    <div
      className="bg-white shadow-md rounded p-4 m-2 cursor-pointer flex flex-col items-center"
      onClick={onClick}
    >
      <img
        className="w-24 h-24 rounded-full mb-4 object-cover"
        src={profilePicture}
        alt={`${username}`}
      />
      <h2 className="text-lg font-bold">{username}</h2>
      <p className="text-gray-600">UID: {uid}</p>
      <p className="text-gray-600">Phone: {phoneNumber}</p>
      <p className="text-gray-400 text-xs">Joined: {dateCreated}</p>
      <button
        onClick={handleDelete}
        className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
      >
        Delete User
      </button>
    </div>
  );
};

export default UserCard;
