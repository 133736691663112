import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { getDataFromUid, requestDeleteUser } from "../firebase/userFunctions";

const UserProfilePage = () => {
  const navigate = useNavigate();
  const { uid } = useParams(); // Extracting the UID from the URL path
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getDataFromUid(uid); // Call function to fetch user data by UID
        setUserData(userData); // Update component state with fetched user data
        console.log("in here");
        console.log(JSON.stringify(userData, null, 2));
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [uid]); // Dependency array includes UID to fetch data when UID changes

  const handleDeleteUser = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      try {
        await requestDeleteUser(uid);
        navigate("/dashboard/users"); // Navigate back to users list after deletion
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const renderValue = (key, value) => {
    if (typeof value === "string" && value.startsWith("https://")) {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          <img src={value} alt={key} className="w-16 h-16 object-cover" />
        </a>
      );
    } else if (typeof value === "object" && value !== null) {
      return renderObject(value);
    } else if (typeof value === "boolean") {
      return value.toString();
    } else {
      return value !== null && value !== undefined ? value : "Missing";
    }
  };

  const renderObject = (obj) => {
    return (
      <div className="ml-4">
        {Object.entries(obj).map(([key, value]) => (
          <div className="flex justify-between py-2" key={key}>
            <span className="font-medium">{key.replace(/_/g, " ")}:</span>
            <span>{renderValue(key, value)}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <NavBar />
      <div className="flex-grow mx-4 overflow-y-auto">
        {userData ? (
          <div className="max-w-3xl mx-auto my-8 p-6 bg-white shadow-md rounded-md">
            <h1 className="text-2xl font-bold my-4">User Profile</h1>

            <div className="flex flex-col items-center mb-6">
              {userData.public_info.profile_picture_url && (
                <a
                  href={userData.public_info.profile_picture_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={userData.public_info.profile_picture_url}
                    alt="Profile"
                    className="rounded-full w-32 h-32 mb-4 object-cover"
                  />
                </a>
              )}
              <h2 className="text-xl font-bold">
                {`${userData.private_info.first_name} ${userData.private_info.last_name}`}
              </h2>
            </div>

            <div className="bg-gray-50 p-4 rounded-md shadow-inner mt-4">
              <h3 className="text-lg font-semibold mb-3">Public Information</h3>
              {Object.entries(userData.public_info).map(([key, value]) => (
                <div className="flex justify-between py-2" key={key}>
                  <span className="font-medium">{key.replace(/_/g, " ")}:</span>
                  <span>{renderValue(key, value)}</span>
                </div>
              ))}
            </div>

            <div className="bg-gray-50 p-4 rounded-md shadow-inner mt-4">
              <h3 className="text-lg font-semibold mb-3">
                Private Information
              </h3>
              {Object.entries(userData.private_info).map(([key, value]) => (
                <div className="flex justify-between py-2" key={key}>
                  <span className="font-medium">{key.replace(/_/g, " ")}:</span>
                  <span>{renderValue(key, value)}</span>
                </div>
              ))}
            </div>

            <div className="bg-gray-50 p-4 rounded-md shadow-inner mt-4">
              <h3 className="text-lg font-semibold mb-3">
                Protected Information
              </h3>
              {Object.entries(userData.protected_info).map(([key, value]) => (
                <div className="flex justify-between py-2" key={key}>
                  <span className="font-medium">{key.replace(/_/g, " ")}:</span>
                  <span>{renderValue(key, value)}</span>
                </div>
              ))}
            </div>
            <button
              className="mt-6 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 flex items-center"
              onClick={handleDeleteUser}
            >
              <FontAwesomeIcon icon={faTrash} className="mr-2" />
              Delete User
            </button>
          </div>
        ) : (
          <p className="text-center text-gray-600 mt-4">Loading...</p>
        )}
      </div>
    </div>
  );
};

export default UserProfilePage;
